.services-body {
    background-color: #000000;
}

.services-row {
    margin-right: 0;
    margin-left: 0;
}

.services-container {
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.services-title {
    color: #DB9015;
    font-family: "Open Sans", Sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 38px;
}

.services-line {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    width: 100%;
}
    .services-line hr {
        margin-top: 0rem;
        margin-bottom: 0rem;
        border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
        border-top: 3px solid #DB9015;
    }

.services-content {
    text-align: justify;
    color: #FFFFFF;
    font-family: "Open Sans", Sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 35px;
}
    .services-content p {
        margin-bottom: 1.6em;
    }
    .services-content ul {
        margin-bottom: 1.6em;
    }
    .services-content li {
        list-style: disc;
        margin-bottom: 5px;
    }
