.contact-body {
    background-color: #121212
}

.contact-container {
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.contact-iframe {
    width: 100%;
    height: 550px;
    padding: 10px;
}

.contact-title {
    color: #DB9015;
    font-family: "Open Sans", Sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 38px;
}

.contact-line {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
}
    .contact-line hr {
        margin-top: 0rem;
        margin-bottom: 0rem;
        border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
        border-top: 3px solid #DB9015;
    }

.contact-content {
    text-align: justify;
    color: #FFFFFF;
    font-family: "Open Sans", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 35px;
}
    .contact-content p {
        margin-bottom: 1.6em;
    }

.contact-info {
    text-align: center;
}

.contact-info-img {
    padding: 2rem 0rem 0rem 0rem;
    margin-bottom: 20px;
}

.contact-info-text {
    color: #FFFFFF;
    font-family: "Ubuntu", Sans-serif;
    font-size: 20px;
    font-weight: 400;
}