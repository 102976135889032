.navbar-brand {
    margin-right: 5rem;
}

.navbar-row .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    width: 100%;
    /*margin-top: 3rem;*/
}

.transparent {
    background-color: transparent !important;
}

/* .navbar-light .navbar-nav .nav-link { */
    .navbar-row .nav-link {
    color: #DB9015 !important;
    background-color: #00000000;
    transition-duration: 500ms;
    padding: 15px 20px !important;
    margin: 5px;
    font-size: 15px;
}

    /* .navbar-light .navbar-nav .nav-link:hover { */
        .navbar-row .nav-link:hover {
        color: #FFFFFF !important;
        background-color: #DB9015;
        -webkit-transform: translateY(8px);
        -ms-transform: translateY(8px);
        transform: translateY(8px);
        transition-duration: 500ms;
    }

    /* .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link { */
        .navbar-row .nav-link .active > .navbar-row .nav-link.active, .navbar-row .nav-link.show, .navbar-row .nav-link .show > .nav-link {
        color: #FFFFFF !important;
        background-color: #DB9015;
        -webkit-transform: translateY(8px);
        -ms-transform: translateY(8px);
        transform: translateY(8px);
        transition-duration: 500ms;
    }

    /* .navbar-light .navbar-nav .nav-link:focus */
    .navbar-row .nav-link:focus
    {
        color: #DB9015;
    }

@media (min-width: 992px) {
    .navbar-row .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

.navbar-row .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.navbar-row .navbar-collapse-m {
    margin-left: 60px;
}

.NavBar-AlwyasTop
{
    background-color: rgba(0,0,0,1);
    position: fixed; 
    z-index: 999; 
    width: 100%;
}

.dropdown-toggle::after {
    display: none !important; 
}

.navbar-row .dropdown-menu.show {
    display: block;
}

.navbar-row .dropdown-menu {
/* .dropdown-menu.show { */
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 15px;
    color: #DB9015;
    text-align: left;
    list-style: none;
    background-color: rgba(0,0,0,1);;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
  }

  .navbar-row .dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    color: #DB9015;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.navbar-row .dropdown-item:focus, .navbar-row .dropdown-item:hover {
    color: #FFFFFF; 
    text-decoration: none;
    background-color: #DB9015;
  }
