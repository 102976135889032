.bottom-body {
    background-color: #000000
}

.bottom-container {
    height: 100%;
    padding-top: 50px;
    padding-top: 4rem;
    padding-bottom: 1rem;
}

.bottom-logo {
    text-align: center;
    width: 100%;
}

.bottom-navbar {
    margin-top: 1rem;
    width: 100%;
}

.bottom-navbar a {
    line-height: 1px;
    cursor: pointer;
    text-decoration: none;
}
.bottom-navbar a:hover {
    line-height: 1px;
    cursor: pointer;
    text-decoration: none;
}
    .bottom-navbar ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .bottom-navbar li {
        list-style: none !important;
        margin: auto 0;
        padding: 0;
    }
        .bottom-navbar li a {
            color: #E0A13A;
            padding: 15px;
            font-size: 17px;
        }

.bottom-info {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    color: #7B7B7B;
}

    .bottom-info h6 {
        margin-bottom: 0rem;
    }


.bottom-body .dropdown-menu{
    /* position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0; */
    font-size: 17px;
    color: #E0A13A;
    /* text-align: left;
    list-style: none; */
    background-color: rgba(0,0,0,1);
    /* background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem; */
}

.bottom-body .dropdown-item {
    /* display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both; */
    /* font-weight: 400; */
    color: #E0A13A;
    /* text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; */
  }

  .bottom-body .dropdown-item.active, .bottom-body .dropdown-item:active {
    color: #FFFFFF;
    background-color: #DB9015;
  }

  .bottom-body .dropdown-item:focus, .bottom-body .dropdown-item:hover {
    color: #FFFFFF ;
    background-color: #DB9015;
  }

  /* .bottom-btn {
    padding: .375rem .75rem;
    color: #E0A13A;
    background-color: transparent;
    border-color: transparent;
  } */

  .bottom-body .btn-primary {
    color: #E0A13A !important;
    padding: .375rem .75rem;
    font-size: 17px;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .bottom-body .btn-primary:hover {
    color: #E0A13A;
    background-color: transparent;
    border-color: transparent;
  }
  
  .bottom-body .btn-primary.focus, .btn-primary:focus {
    color: #E0A13A;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }