.kan-body {
    background-color: #000000
}

.kan-container {
    padding-top: 3rem
}

.kan-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;
}

.kan-title {
    color: #DB9015;
    font-family: "Open Sans", Sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 38px;
}

.kan-line {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
}
    .kan-line hr {
        margin-top: 0rem;
        margin-bottom: 0rem;
        border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
        border-top: 3px solid #DB9015;
    }

.kan-subtitle {
    color: #DB9015;
    font-family: "Open Sans", Sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 20px;
}

.kan-content {
    text-align: justify;
    color: #FFFFFF;
    font-family: "Open Sans", Sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
}
    .kan-content p {
        margin-bottom: 1.6em;
        text-indent: 40px;
    }