#hcg-slider .hcg-slide-container {
	width: auto;
	height: auto;
}
.hcg-slider {
	text-align: center;
	font-family: Arial, Helvetica, sans-serif;
}
.hcg-slide-container {
	max-width: 100%;
	display: inline-block;
	position: relative;
}
.hcg-slides {
	display: none;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	/* border: solid 1px #a0a0a0; */
}
.hcg-slides img {
	max-width: 100%;
	max-height: 100%;
	display: inline-block;
}
/************CSS Animation***********/

.animated { 
	animation-name: fadeInLeftBig; 
	animation-duration: 2s;
}
@keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		transform: translateX(-200px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
.fadeInLeftBig {
	animation-name: fadeInLeftBig;
}