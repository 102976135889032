.header-body {
    height: 635px;
    color: #DB9015
}

.header-body h4 {
    font-size: 30px;
    font-weight: 400;
}

.header-body h1 {
    font-size: 56px;
    font-weight: 400;
}