.App-header {
    background-image: url("https://adwokatboruta.pl/wp-content/uploads/2021/11/toga.jpg");
    background-position: center;
    height: 768px;
}

@media (min-width: 768px) {
    .container, .container-md, .container-sm {
    max-width: 992px;
    }
}
@media (min-width: 576px) {
    .container, .container-sm {
    max-width: 992px;
    }
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}
